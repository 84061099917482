const firebaseKeys =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? {
        apiKey: 'AIzaSyCgiq0oHv-vu6Sf2EIT49eECdm4B0o3XN8',
        authDomain: 'wakao-production.firebaseapp.com',
        projectId: 'wakao-production',
        storageBucket: 'wakao-production.appspot.com',
        messagingSenderId: '877124451922',
        appId: '1:877124451922:web:6d8bd68de77a974d2da98b',
        measurementId: 'G-0S7FNVXW70',
      }
    : {
        apiKey: 'AIzaSyAAeUakFj9fISVunm3fuOEKc82Qn4G7hLk',
        authDomain: 'wakao-app.firebaseapp.com',
        projectId: 'wakao-app',
        storageBucket: 'wakao-app.appspot.com',
        messagingSenderId: '838467946360',
        appId: '1:838467946360:web:3aa3009e4425497967ea8d',
        measurementId: 'G-K4QF9HEESQ',
      };

export default firebaseKeys;
