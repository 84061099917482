import { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';

import { apiGetMediaUploadUrl, apiUploadMediaFile } from '../../../services';
import { backgroundMediaUploaderState } from '../../../recoilState/MediaUploader';
import { userAuthDetails } from '../../../recoilState/authState';

interface UploadableMedia {
  file: File;
  error: string;
  retry: number;
  fileId: string;
  blockId: string;
  uploadUrl: string;
  downloadUrl: string;
  uploadedFileName: string;
  extraData: Record<string, any>;
  preSignedUrlParams: Record<string, any>;
}

export interface UploadableMediaBlock {
  mediaBlockId: string;
  files: UploadableMedia[];
  preSignedUrlParams: Record<string, string | number>;
  onDone: (
    mediaBlock: Omit<UploadableMediaBlock, 'onDone'>,
    enqueueSnackbar: CallableFunction
  ) => void;
}

const MAX_RETRY_COUNT = 2;

/**
 * @description This component is responsible to upload the media file in the background with a onDone callback.
 * @param {string} mediaBlockId - Random and unique media block id.
 * @param {UploadableMedia[]} files - Media files that is to be uploaded.
 * @param {Record<string, string | number>} preSignedUrlParams - pre-signed API params.
 * @param {CallableFunction} onDone - Callback function to be called when the media file is uploaded.
 * @returns {UploadableMediaBlock}
 * @example
    import { useSetRecoilState } from 'recoil';
    import { backgroundMediaUploaderState } from '../../../recoilState/MediaUploader';

    const updateMediaToUpload = useSetRecoilState(backgroundMediaUploaderState);

    // to upload the media file set this recoil atom 
    updateMediaToUpload((currentMedia) => [
        ...currentMedia,
        {
          mediaBlockId: new Date().getTime() + contestId,  (just a random unique id)
          mediaFiles: [file1, file2, file3],  (all the media files you want to upload)
          preSignedUrlParams: {   (any extra param that is require to get pre signed url 'type' always required)
            contest_id: contestId,
            type: 'entry',
          },
          onDone:   (Callback after uploading / max retry to upload all the media files)
        },
    ]);

 * @Terminology Terminology used in this component:
 *  - mediaBlock: A media block is a group of media files that are uploaded together and has only one callback.
 *  - mediaBlockId: unique id for each media block
 *  - mediaToUploadStack: A media to upload is a media block with all the media files that are going to be uploaded (FIFO stack).
 *  - errorBlockStack: A error block is a group of media files that are failed to upload (FIFO stack).
 *  - uploadedMediaStack: A uploaded media is a media block that is successfully uploaded and onDone callback is also called (FIFO stack).
 *  - currentMediaBlock: A current media block is the media block that is currently being uploaded/processed.
 *  - currentMedia: A current media is one of media file from currentMediaBlock that is currently being uploaded.
 *  - fileIdToUpload: A fileIdToUpload is a unique id of the currentMedia file that is going to be uploaded (used to prevent recursive useEffect call on updating currentMedia).
 * 
 * @methodology This component is initiated when some value is update to 'backgroundMediaUploaderState' recoil atom.
 * - When the value is updated, the component will extracted all the newly added media blocks and push them to mediaToUploadStack.
 * - When the value is updated in mediaToUploadStack, the component will take th block at 0th index and updates the currentMediaBlock
 * - Then from currentMediaBlock a file is pick to upload.
 * - Then the component will call the API to get pre-signed url for the file.
 * - Then the component will upload the file to the pre-signed url.
 * - Then the component will check if the file is uploaded successfully or not.
 * - If the file is uploaded successfully, the component will call the onDone callback and moves the currentMediaBlock from mediaToUploadStack to uploadedMediaStack.
 * - If the file is not uploaded successfully, that media block is moved to errorBlockStack.
 * - If the file is failed to upload more than max retry count, the component will call the onDone callback and moves the currentMediaBlock from errorBlockStack to uploadedMediaStack.
 */
const BackgroundMediaUploader = () => {
  const userData = useRecoilValue(userAuthDetails);
  const { enqueueSnackbar } = useSnackbar();
  const mediaStack = useRecoilValue(backgroundMediaUploaderState);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedMediaStack, setUploadedMediaStack] = useState<
    Record<string, string>[]
  >([]);
  const [mediaToUploadStack, setMediaToUploadStack] = useState<any[]>([]);
  const [errorBlockStack, setErrorBlockStack] = useState<
    UploadableMediaBlock[] | null
  >(null);
  const [currentMediaBlock, setCurrentMediaBlock] =
    useState<UploadableMediaBlock | null>(null);
  const [fileIdToUpload, setFileIdToUpload] = useState<string>('');
  const [currentMedia, setCurrentMedia] = useState<UploadableMedia | null>(
    null
  );

  // this side effect is used to acknowledge the newly added media block and formate them accordingly
  useEffect(() => {
    if (mediaStack.length > 0) {
      setMediaToUploadStack((pre: any) => {
        const acknowledgedMedia = [
          ...pre,
          ...(errorBlockStack || []),
          ...(uploadedMediaStack || []),
        ];
        // This filters all the media blocks that are newly added to the stack by the user
        const unAcknowledgedMedia = mediaStack.filter(
          (m: any) =>
            acknowledgedMedia.findIndex(
              (d: any) => d.mediaBlockId === m.mediaBlockId
            ) === -1
        );

        const modifiedUnAcknowledgedMedia = unAcknowledgedMedia.map((item) => {
          return {
            onDone: item.onDone,
            mediaBlockId: item.mediaBlockId,
            preSignedUrlParams: item.preSignedUrlParams || {},
            files: item.mediaFiles.map((mediaFile: any) => {
              // These details are required to keep track of file getting uploaded
              return {
                file: mediaFile?.filePreSignedUrlParams
                  ? mediaFile.file
                  : mediaFile,
                blockId: item.mediaBlockId,
                fileId: new Date().getTime() + Math.random(),
                retry: 0,
                error: '',
                uploadUrl: '',
                downloadUrl: '',
                uploadedFileName: '',
                preSignedUrlParams: {
                  ...(item.preSignedUrlParams || {}),
                  ...mediaFile?.preSignedUrlParams,
                },
                extraData: mediaFile.extraData,
              };
            }),
          };
        });
        return [...pre, ...modifiedUnAcknowledgedMedia];
      });
    }
  }, [mediaStack]);

  // this is used to put a media block under process / uploading from the mediaToUploadStack or errorBlockStack stack
  // files from errorBlockStack are only picked once all mediaToUploadStack is empty.
  useEffect(() => {
    if (!currentMediaBlock || Object.keys(currentMediaBlock).length === 0) {
      if (mediaToUploadStack.length > 0)
        setCurrentMediaBlock(mediaToUploadStack[0]);
      else if (Array.isArray(errorBlockStack) && errorBlockStack.length > 0)
        setCurrentMediaBlock(errorBlockStack[0]);
    }
  }, [mediaToUploadStack, errorBlockStack]);

  // this is used to pick a media file to upload from the currentMediaBlock
  useEffect(() => {
    if (
      currentMediaBlock &&
      Object.keys(currentMediaBlock).length > 0 &&
      Array.isArray(currentMediaBlock?.files)
    ) {
      const mediaFile = currentMediaBlock?.files.find(
        (item: any) => !item.downloadUrl && item.retry < MAX_RETRY_COUNT
      );
      if (mediaFile) {
        if (currentMedia?.error) {
          // push currentMedia to last index of errorBlockStack
          setErrorBlockStack((pre: any) => {
            if (!Array.isArray(pre)) return [currentMediaBlock];
            return [
              ...pre.filter((item: any) => {
                return item.mediaBlockId !== currentMediaBlock.mediaBlockId;
              }),
              currentMediaBlock,
            ];
          });

          // remove currentMedia from currentMediaBlockStack
          setMediaToUploadStack((pre: any) => {
            return pre.filter(
              (item: any) =>
                item.mediaBlockId !== currentMediaBlock.mediaBlockId
            );
          });
          setCurrentMedia(null);
          setFileIdToUpload('');
          setCurrentMediaBlock(null);
        } else {
          setCurrentMedia((pre) => {
            if (pre?.fileId !== mediaFile.fileId) return mediaFile;
            return pre;
          });
          setFileIdToUpload(mediaFile.fileId);
        }
      } else {
        const { onDone, ...rest } = currentMediaBlock;
        currentMediaBlock.onDone(rest, enqueueSnackbar);
        setUploadedMediaStack((pre) => [
          ...pre,
          { mediaBlockId: currentMediaBlock.mediaBlockId },
        ]);
        incrementMediaBlock();
      }
    }
  }, [currentMediaBlock]);

  const incrementMediaBlock = () => {
    if (!currentMediaBlock) return;

    setCurrentMediaBlock(null);
    setCurrentMedia(null);
    setFileIdToUpload('');
    if (mediaToUploadStack.length > 0)
      setMediaToUploadStack((prev: any) =>
        prev.filter((item: any) => {
          return item.mediaBlockId !== currentMediaBlock.mediaBlockId;
        })
      );
    if (Array.isArray(errorBlockStack) && errorBlockStack.length > 0)
      setErrorBlockStack((prev: any) =>
        prev.filter((item: any) => {
          return item.mediaBlockId !== currentMediaBlock.mediaBlockId;
        })
      );
  };

  const onErrorUploading = (err: any) => {
    if (!currentMediaBlock || !currentMedia) return;

    const errorBlock = {
      ...currentMedia,
      error: String(err),
      retry: currentMedia.retry + 1,
      uploadUrl: '',
      downloadUrl: '',
      uploadedFileName: '',
    };

    setCurrentMediaBlock((pre: UploadableMediaBlock | null) => {
      if (!pre) return null;
      return {
        ...pre,
        files: [
          ...pre.files.filter((item) => {
            return item.fileId !== errorBlock.fileId;
          }),
          errorBlock,
        ],
      };
    });
    setCurrentMedia(errorBlock);
    setFileIdToUpload('');
    setUploadProgress(0);
  };

  // This is used to get pre signed upload url from the api
  useEffect(() => {
    if (
      !currentMedia ||
      currentMedia.uploadUrl ||
      currentMedia.downloadUrl ||
      currentMedia.retry >= MAX_RETRY_COUNT ||
      currentMedia.fileId !== fileIdToUpload
    )
      return;

    apiGetMediaUploadUrl({
      user_id: userData?.id || '',
      file_type: currentMedia.file?.name?.split('.').pop() || '',
      ...currentMedia.preSignedUrlParams,
    })
      .then((response: any) => {
        const res = response.data;
        if (res.results) {
          uploadMedia({
            ...currentMedia,
            uploadUrl: res.results.url,
            uploadedFileName: res.results.file_name,
          });
        }
      })
      .catch((error: any) => {
        const err = error.data;
        enqueueSnackbar(
          `Unable to get upload link for File: ${
            currentMedia.file.name
          } of Size: ${Math.round(currentMedia.file.size / 1024 / 1024)} MB`,
          { variant: 'error' }
        );
        onErrorUploading(err);
      });
  }, [currentMedia]);

  // This is used to upload the media file on pre signed url
  const uploadMedia = (mediaFileToUpload: any) => {
    apiUploadMediaFile(
      mediaFileToUpload.uploadUrl,
      mediaFileToUpload.file.extraData
        ? mediaFileToUpload.file.file
        : mediaFileToUpload.file,
      setUploadProgress
    )
      .then(() => {
        const successBlock = {
          ...mediaFileToUpload,
          downloadUrl: mediaFileToUpload.uploadedFileName,
          retry: 0,
          error: '',
        };
        setCurrentMediaBlock((pre: any) => {
          return {
            ...pre,
            files: pre.files.map((item: UploadableMedia) => {
              if (item.fileId === successBlock.fileId) return successBlock;
              return item;
            }),
          };
        });
        setCurrentMedia(successBlock);
        setUploadProgress(0);
        setFileIdToUpload('');
      })
      .catch((error: any) => {
        const err = error.data;
        enqueueSnackbar(
          `Uploading failed for ${
            mediaFileToUpload.file.name
          } of size ${Math.round(mediaFileToUpload.file.size / 1024 / 1024)} MB`
        );
        onErrorUploading(err);
      });
  };

  return (
    <Snackbar
      open={!!uploadProgress}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={() => setUploadProgress(0)}
    >
      <Alert
        onClose={() => setUploadProgress(0)}
        severity="info"
        sx={{ width: '100%' }}
      >
        {`Uploading file: ${uploadProgress}% (${
          currentMedia?.file?.name || ''
        })`}
      </Alert>
    </Snackbar>
  );
};

export default BackgroundMediaUploader;
