import { atom } from 'recoil';
import { UploadableMediaBlock } from '../components/Atom/BackgroundMediaUploader/BackgroundMediaUploader';

export type uploadableMediaFile = {
  file: File;
  preSignedUrlParams?: Record<string, string>;
  extraData?: Record<string, string | number>;
};

interface MediaUploaderState {
  mediaBlockId: string | number;
  mediaFiles: File[] | uploadableMediaFile[];
  onDone: (
    mediaBlock: UploadableMediaBlock,
    enqueueSnackbar: CallableFunction
  ) => void;
  preSignedUrlParams?: Record<string, string>;
}

export const backgroundMediaUploaderState = atom({
  key: 'BackgroundMediaUploader',
  default: [] as MediaUploaderState[],
});
