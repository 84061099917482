const RoutesUrls = {
  AppConfig: '/dashboard/app-configs',
  AppData: '/dashboard/app-data',
  Banners: '/dashboard/app-configs/banners',
  Categories: '/dashboard/app-configs/categories',
  Contest: '/dashboard/contest',
  ContestPage: '/dashboard/contest/:contestId/:pageName?',
  Dashboard: '/dashboard',
  DefaultUrl: '/dashboard',
  LeaderboardWidget: '/dashboard/app-configs/leaderboard-widget',
  Login: '/login',
  Logs: '/dashboard/app-configs/logs',
  PackagingContent: '/dashboard/contest/:contestId/packaging-content/:stageId',
  Users: '/dashboard/app-configs/users',
  StageCollection: '/dashboard/app-configs/stage-collection',
  FeedCollection: '/dashboard/app-configs/feed-collection',
  WakaoStars: '/dashboard/app-configs/wakao-stars'
};

export default RoutesUrls;
