/* eslint-disable complexity */

import axios from 'axios';
import { get, post, patch, deleteCall, put } from './network';
import URL, { baseUrl } from '../Constants/Network';
import { DEFAULT_ROW_PER_PAGE, REFRESH_TOKEN, TOKEN } from '../Constants/Enums';
import {
  apiBannersListParams,
  apiCategoryListParams,
  apiContestListParams,
  apiCreateLogParams,
  apiOverviewParams,
  apiStreamsParams,
  apiWidgetListParams,
} from '../types/Services.schema';
import { Dropdown } from '../types/CommonTypes.schema';
import { createStageDataTypes } from '../types/createContest.schema';
import { utils } from '../Utils';
import { allCategoryListResponseSchema } from '../types/categories.schema';
import keys from '../../config/keys';

export async function apiAuth(params: Record<string, string>) {
  if (params.id) return get(URL.userList, params);
  return post(URL.userList, params);
}

export async function apiAuthToken() {
  const token = utils.getDataFromLocalStorage(REFRESH_TOKEN);
  return get(URL.refreshToken, {}, { Authorization: `Bearer ${token}` });
}

export async function apiContestList({
  type,
  offset,
  limit = DEFAULT_ROW_PER_PAGE,
}: apiContestListParams) {
  return get(
    URL.contestList,
    { type, offset, limit },
    {},
    {
      errMsg: 'Unable to fetch contest list',
      hideDefaultError: false,
    }
  );
}

export async function apiOverview({ contestId }: apiOverviewParams) {
  return get(`${URL.overview}/${contestId}`);
}

export async function apiGetStreams(params: Record<string, any>) {
  return get(URL.streams, { ...params, ordering: '-created_at' });
}

export async function apiGetStageStreams({
  type = 'RECORDED',
  status,
  stageId,
  limit = DEFAULT_ROW_PER_PAGE,
  offset,
  ordering = '-created_at',
  assignedToUserId = '',
  showUnassigned = false,
  showUnscored = false,
  scoreRange,
  userId = null,
  streamId = null,
  contestType,
}: apiStreamsParams) {
  const data = streamId
    ? { q: streamId, stage_id: stageId }
    : {
      type,
      limit,
      offset,
      ...(utils.isContestNeutral(contestType) ? { neutral_stage_id: stageId } : { stage_id: stageId }),
      ...(status !== 'ACCEPTED' && { status }),
      ...(status === 'ACCEPTED' && { status__in: 'ACCEPTED,LIVE' }),
      ...(!showUnassigned &&
        assignedToUserId && { assigned_to_user_id: assignedToUserId }),
      ...(showUnassigned && { assigned_to_user_id__isnull: showUnassigned }),
      ...(showUnscored && { executive_score__isnull: showUnscored }),
      ...(scoreRange?.showRange &&
        scoreRange?.value?.length > 0 && {
        executive_score__gte: scoreRange.value[0],
        executive_score__lte: scoreRange.value[1],
      }),
      ...(userId && { user_id: userId }),
      ...(contestType === 'BATTLE' && { battle: true }),
      ordering,
    };
  return get(URL.streams, data);
}

export async function apiCreateContest(params: Record<string, any>) {
  return post(URL.createContest, params);
}

export async function apiModifyContest(params: Record<string, any>) {
  return patch(`${URL.createContest}/${params.contestId}`, params);
}

export async function apiAssignTo(params: Record<string, string>) {
  return patch(URL.streams, params);
}

export async function apiStreamScoring(params: Record<string, string>) {
  return post(URL.streamActions, params);
}

export async function apiStreamAction(params: Record<string, string>) {
  return patch(URL.streams, params);
}

export async function apiCreateUser(params: Record<string, string>) {
  return post(URL.usersV1, params);
}

export async function apiUserList(params: Record<string, string>) {
  return get(URL.userList, params) as Promise<{ results: Dropdown[] }>;
}

export async function apiUpdateUser(
  params: Record<string, string | Record<string, string>>
) {
  return patch(`${URL.userList}/${params.userId}`, params.payload);
}

export async function apiCategoryList() {
  return get(URL.contestCategory) as Promise<{ results: Dropdown[] }>;
}

export async function apiSubCategoryList(params: Record<string, string>) {
  return get(URL.subCategory, params) as Promise<{ results: Dropdown[] }>;
}

export async function apiAddStage(stageData: createStageDataTypes) {
  const formattedStageData = {
    rules: stageData.rules,
    contest: stageData.contest,
    title: stageData.title,
    challenge_type: stageData.challenge_type,
    start_date: stageData.start_date?.toISOString(),
    end_date: stageData.end_date?.toISOString(),
    participation_start_date: stageData.participation_start_date?.toISOString(),
    participation_end_date: stageData.participation_end_date?.toISOString(),
    voting_start_date: stageData.voting_start_date?.toISOString(),
    voting_end_date: stageData.voting_end_date?.toISOString(),
    ...(stageData.is_active && { is_active: stageData.is_active }),
    ...(stageData.thumbnail && { thumbnail: stageData.thumbnail }),
    ...(stageData.secondary_thumbnail && { secondary_thumbnail: stageData.secondary_thumbnail }),
    ...(stageData.description && { description: stageData.description }),
    ...(stageData.theme && { theme: stageData.theme }),
    ...(stageData.winner_reveal_date && { winner_reveal_date: stageData.winner_reveal_date?.toISOString() }),
    ...(stageData.category && { category: stageData.category.value }),
    ...(stageData.sub_category && { subcategory: stageData.sub_category.value }),
  };

  return post(URL.contestStage, { data: [formattedStageData] });
}
/* eslint-disable complexity */

export async function apiModifyStage({
  changedStageData,
  stageId,
}: {
  changedStageData: createStageDataTypes;
  stageId: string;
}) {
  const formattedStageData = {
    ...(changedStageData.rules && {
      rules: changedStageData.rules,
    }),
    ...(changedStageData.contest && {
      contest: changedStageData.contest,
    }),
    ...(changedStageData.title && {
      title: changedStageData.title,
    }),
    ...(changedStageData.start_date && {
      start_date: changedStageData.start_date,
    }),
    ...(changedStageData.end_date && {
      end_date: changedStageData.end_date,
    }),
    ...(changedStageData.participation_start_date && {
      participation_start_date: changedStageData.participation_start_date,
    }),
    ...(changedStageData.participation_end_date && {
      participation_end_date: changedStageData.participation_end_date,
    }),
    ...(changedStageData.voting_start_date && {
      voting_start_date: changedStageData.voting_start_date,
    }),
    ...(changedStageData.voting_end_date && {
      voting_end_date: changedStageData.voting_end_date,
    }),
    ...(changedStageData.thumbnail && {
      thumbnail: changedStageData.thumbnail,
    }),
    ...(changedStageData.secondary_thumbnail && {
      secondary_thumbnail: changedStageData.secondary_thumbnail,
    }),
    ...(changedStageData.theme && {
      theme: changedStageData.theme,
    }),
    ...(changedStageData.winner_reveal_date && {
      winner_reveal_date: changedStageData.winner_reveal_date,
    }),
    ...(changedStageData.category && {
      category: changedStageData.category.value,
    }),
    ...(changedStageData.sub_category && {
      subcategory: changedStageData.sub_category.value,
    }),
    ...(changedStageData.description && {
      description: changedStageData.description,
    }),
    ...(typeof changedStageData.boost === 'number' && {
      boost_score: changedStageData.boost,
    }),

  };

  return patch(`${URL.contestStage}/${stageId}`, formattedStageData);
}
/* eslint-enable complexity */

export async function apiGetMediaUploadUrl(params: Record<string, string>) {
  return get(URL.getMediaUploadUrl, params);
}

export async function apiUploadMediaFile(
  url: string,
  file: File,
  onProgressCallback: any = null
) {
  // since the header of 'Content-Type': file.type was not working from here so, directly axios.put has been used here
  return axios.put(url, file, {
    headers: {
      'x-amz-acl': 'public-read',
      'Content-Type': file.type,
      'Cache-Control': 'no-cache',
    },
    ...(onProgressCallback &&
      typeof onProgressCallback === 'function' && {
      onUploadProgress: (progressEvent) =>
        onProgressCallback(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        ),
    }),
  });
}

export async function apiDownload(url: string) {
  const token = utils.getDataFromLocalStorage(TOKEN);
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    timeout: 120000
  }
  return axios.get(url, config)
}

export async function apiAncillaryCategory(contestId: string) {
  return get(URL.ancillaryCategory, { contest_id: contestId });
}

export async function apiOverviewTrailerUpload(params: Record<string, string>) {
  return post(URL.overviewTrailerUpload, params);
}

export async function apiOverviewModifyTrailer(params: Record<string, string>) {
  return put(`${URL.overviewTrailerUpload}/${params.id}`, params);
}

export async function apiOverviewDeleteTrailer(id: string) {
  return deleteCall(
    `${URL.overviewTrailerUpload}/${id}`,
    {},
    {},
    { hideDefaultError: true }
  );
}

export async function apiSubmitAncillaryForm(params: any) {
  return post(URL.ancillaryContent, params);
}

export async function apiUpdateAncillaryForm(params: any) {
  const { id, ...apiParams } = params || {};
  return put(`${URL.ancillaryContent}/${id}`, apiParams);
}

export async function apiDeleteAncillaryForm(id: any) {
  return deleteCall(`${URL.ancillaryContent}/${id}`);
}

export async function apiGetAncillaryContent(params: any) {
  return get(URL.ancillaryContent, params);
}

export async function apiDeleteAncillaryContent(ancillaryId: any) {
  return deleteCall(`${URL.ancillaryContent}/${ancillaryId}`);
}

export async function apiUploadPackagingContent(
  params: Record<string, string>
) {
  return post(URL.streams, params);
}

export async function apiUpdatePackagingContent(
  params: Record<string, string>
) {
  return patch(URL.streams, { data: [params] });
}

export async function apiGetPackagingContent(params: Record<string, string>) {
  return get(URL.streams, {
    ordering: 'order',
    status__in: 'ACCEPTED,LIVE',
    type: 'RECORDED',
    offset: 0,
    limit: DEFAULT_ROW_PER_PAGE,
    ...params,
  });
}

export async function apiUploadPackagingPriority(params: any[]) {
  return post(URL.participantOrder, { data: params });
}

export async function apiContestDetails(contestId: string) {
  return get(
    `${URL.contestList}/${contestId}`,
    {},
    {},
    {
      errMsg: 'Unable to fetch contest list',
    }
  );
}

export async function apiUploadContestVideo(
  params: Record<string, string>
) {
  return post(URL.streams, params);
}

export async function apiModifyContestVideo(
  params: Record<string, string>
) {
  return patch(`${URL.streams}?stream_id=${params.id}`, params);
}

export async function apiStageGoLive(id: string) {
  return patch(`${URL.stage}/${id}`, { entry_status: 'LIVE' });
}

export async function apiStageDelete(id: string) {
  return deleteCall(`${URL.stage}/${id}`);
}

export async function apiOverviewGoLive(id: string) {
  return patch(`${URL.contestList}/${id}`, { is_published: true });
}

export async function apiRefreshLeaderboard(id: string) {
  return patch(`${URL.leaderboard}/calculate?contest_id=${id}`);
}

export async function apiBannersList({
  banner = true,
  type,
  offset,
  limit = DEFAULT_ROW_PER_PAGE,
}: apiBannersListParams) {
  return get(
    URL.banners,
    { type, offset, limit, banner },
    {},
    {
      errMsg: 'Unable to fetch banners list',
      hideDefaultError: false,
    }
  );
}

export async function apiAllCategoryList({
  limit = DEFAULT_ROW_PER_PAGE,
}: apiCategoryListParams) {
  return get(
    URL.allCategory,
    { limit },
    {},
    {
      errMsg: 'Unable to fetch Categories',
      hideDefaultError: false,
    }
  ) as Promise<allCategoryListResponseSchema>
}

export async function apiCreateCategory(params: Record<string, string>) {
  return post(URL.contestCategory, params);
}

export async function apiModifyCategory(params: Record<string, string>) {
  return put(`${URL.contestCategory}/${params.id}`, params);
}

export async function apiCreateSubCategory(params: Record<string, string>) {
  return post(`${URL.contestSubCategory}?category_id=${params.category}`, params);
}

export async function apiModifySubCategory(params: Record<string, string>) {
  return put(`${URL.contestSubCategory}?category_id=${params.category}`, params);
}

export async function apiUploadBanner(params: Record<string, string>) {
  return post(URL.banners, params);
}

export async function apiModifyBanner(params: Record<string, string>) {
  return put(`${URL.banners}/${params.id}`, params);
}

export async function apiDeleteBanner(bannerId: any) {
  return deleteCall(`${URL.banners}/${bannerId}`);
}

export async function apiWidgetList({
  widget = true,
  type,
  offset,
  limit = DEFAULT_ROW_PER_PAGE,
}: apiWidgetListParams) {
  return get(
    URL.banners,
    { type, offset, limit, widget },
    {},
    {
      errMsg: 'Unable to fetch widget list',
      hideDefaultError: false,
    }
  );
}

export async function apiUploadWidget(params: Record<string, string>) {
  return post(URL.banners, params);
}

export async function apiModifyWidget(params: Record<string, string>) {
  return patch(`${URL.banners}/${params.id}`, params);
}

export async function apiDeleteWidget(bannerId: any) {
  return deleteCall(`${URL.banners}/${bannerId}`);
}

export async function apiGetNotifications(params: any) {
  return get(URL.notifications, params);
}

export async function apiUploadBattleStream(params: Record<string, string>) {
  return post(URL.streamV2, params);
}

export async function apiModifyBattleStream(params: Record<string, string>) {
  return patch(`${URL.streamV2}?stream_id=${params.id}`, params);
}

export async function apiGetStage(params: { stageId: string }) {
  return get(`${URL.contestStage}/${params.stageId}`);
}
export async function apiGetStageList(params: any) {
  return get(URL.contestStage, { ordering: '-created_at', ...params });
}

export async function apiGetLogs(params: any) {
  return get(URL.logs, params);
}

export async function apiCreateLog(params: apiCreateLogParams) {
  return post(URL.logs, params);
}

export async function apiGetPackagingPriority(params: any) {
  return get(URL.participantOrder, params);
}

export async function apiGetBranchData(params: Record<string, string>) {
  return axios.get(`https://api2.branch.io/v1/url?url=${params.link}&branch_key=${keys.branch}`);
}

export async function apiGetStageCollection(params: any) {
  return get(URL.stageCollection, params);
}

export async function apiCreateStageCollection(params: Record<string, string>) {
  return post(URL.stageCollection, params);
}

export async function apiModifyStageCollection(params: Record<string, string>) {
  const { id, ...apiData } = params
  return patch(`${URL.stageCollection}/${id}`, apiData);
}

export async function apiGetWakaoStars(params: any) {
  return get(URL.wakaoStars, params);
}

export async function apiCreateWakaoStars(params: Record<string, string>) {
  return post(URL.wakaoStars, params);
}

export async function apiModifyWakaoStars(params: Record<string, string>) {
  const { id, ...apiData } = params
  return patch(`${URL.wakaoStars}/${id}`, apiData);
}

export async function apiCreateFeedCollection(params: Record<string, string>) {
  return post(URL.feedCollection, params);
}

export async function apiGiveUsersBlueTick(params: Record<string, string>) {
  return post(URL.userBlueTick, params);
}

export async function apiCreateAliases(params: Record<string, string>) {
  return post(URL.userHotKeyword, params);
}

export async function apiUploadPaymentSheet(params: File) {
  const token = utils.getDataFromLocalStorage(TOKEN);
  const formData = new FormData();
  formData.append("file", params);
  return axios.post(`${baseUrl.api}${URL.payoutResults}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`,
    }
  });
}

