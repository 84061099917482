const URL = {
  actionService: 'actionservice',
  contestStage: 'contestservice/stages',
  ancillaryCategory: 'contestservice/ancillary_category',
  ancillaryContent: 'contestservice/ancillary_content',
  banners: 'contestservice/banners',
  contestCategory: 'contestservice/contests_category',
  subCategory: 'contestservice/sub_category',
  contestSubCategory: 'contestservice/sub_category',
  allCategory: 'contestservice/categories',
  contestList: 'contestservice/contests',
  createContest: 'contestservice/contests',
  getMediaUploadUrl: 'contestservice/get_presigned_url',
  leaderboard: 'leaderboardservice/leaderboard',
  notifications: 'notificationservice/admin-notifications',
  overview: 'contestservice/contests',
  overviewTrailerUpload: 'contestservice/trailers',
  refreshToken: 'refresh-token',
  stage: 'contestservice/stages',
  streams: 'streamservice/streams',
  streamActions: 'actionservice/action',
  userList: 'userservice/users',
  streamV2: 'v2/streamservice/battle-stream',
  usersV1: '/users/v1/userservice/users',
  logs: 'changesetservice/changeset',
  participantOrder: '/contestservice/participant-order',
  stageCollection: '/contestservice/stage-collection',
  feedCollection: '/users/v1/feedservice/v3/feed/store_user_stages',
  wakaoStars: '/userservice/wakao-stars',
  userBlueTick: '/userservice/update-user-blue-tick',
  userHotKeyword: 'userservice/hot-keyword-update',
  payoutResults: 'ledgerservice/payouts_results_upload'
};


const baseUrl = {
  api:
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'https://api.wakao.app/'
      : 'https://test-api.wakao.app/',
  dummyUrl: 'https://api.instantwebtools.net/v1/',
  media:
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'https://media.wakao.app/'
      : 'https://test-media.wakao.app/',
  stream:
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'https://stream.wakao.app/'
      : 'https://test-stream.wakao.app/',

  // FOR PROD ENV
  // media: 'https://media.wakao.app/',
  // stream: 'https://stream.wakao.app/',

  // FOR TESTING ENV
  // https://test-stream.wakao.app
  // https://test-media.wakao.app
};

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

/**
 * These config options can be passed to the api options
 * errMsg: Error message to be shown in case of error
 * hasFormData - to convert api data into form data
 * fullResponse - to get the actual axios api response (only data object in the apu response is returned)
 * hideDefaultError - to hide the default error message (use in case of custom conditional error message)
 * onProgressCallback - to get the progress of the upload (in put and post requests)
 */
const DEFAULT_INTERCEPTOR_CONFIG = {
  errMsg: '',
  hasFormData: false,
  fullResponse: false,
  hideDefaultError: false,
  onProgressCallback: null,
};

export { COMMON_HEADERS, DEFAULT_INTERCEPTOR_CONFIG, baseUrl };
export default URL;
