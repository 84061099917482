/* eslint-disable complexity */
import React, { FC } from 'react';
import dayjs from 'dayjs';
import { getAuth } from '@firebase/auth';
import { useSetRecoilState } from 'recoil';

import { TOKEN, REFRESH_TOKEN } from '../Constants';
import { history } from '.';
import RoutesUrls from '../Router/RouteUrls';
import { userAuthDetails } from '../recoilState/authState';
import { TimelinesDataTypes } from '../types/CommonTypes.schema';

const getURLParameter = (qrString: string, paramName: string): string => {
  const qr = qrString.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${paramName}=([^&#]*)`);
  const results = regex.exec(qr);

  return results && results.length > 0
    ? decodeURIComponent(results[1].replace(/\+/g, ' '))
    : '';
};

const setDataToLocalStorage = (key: string, value: string): void => {
  window.localStorage.setItem(key, value);
};

const getDataFromLocalStorage = (key: string) => {
  return window.localStorage.getItem(key);
};

const removeDataFromLocalStorage = (key: string) => {
  if (key === TOKEN) {
    window.localStorage.clear();
  }
  return window.localStorage.removeItem(key);
};

// check is development or production mode.
const isDev = (): Boolean => {
  const env = process.env.NODE_ENV;
  return env === 'development';
};

const dateFormatter = (date?: string, format = 'DD/MM/YYYY') => {
  if (!date) return '';
  return dayjs(date).format(format);
};

const getTime = (date?: string) => {
  if (!date) return '';

  return new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
};

/**
 * @function getChangedValues
 * @description function does a shallow comparison between all the values of
 *  `updatedData` and `prevData` object to return only changed key-value.
 * ---
 * #### Returns - All the key of 2nd param with changed values and new keys (compared to 1st param) are returned
 * ---
 * @param prevData {Object}
 * @param updatedData {Object}
 *
 * @return
 * - Object {...}
 * - empty object {}: in case of no new value found
 */

function getChangedValues(
  prevData: Record<string, any>,
  updatedData: Record<string, any>
): Record<string, any> | null {
  const changedValues: Record<string, any> = {};

  Object.entries(updatedData).forEach(([key, value]) => {
    const prevValue = prevData[key];
    if (!prevValue && !value) return;
    // TODO: Add option for Dropdown and check if value is array / object.
    // eslint-disable-next-line eqeqeq
    if (prevValue != value && value !== undefined) {
      if (prevValue instanceof Date || value instanceof Date) {
        const prevDate = new Date(prevValue);
        prevDate.setSeconds(0);
        prevDate.setMilliseconds(0);
        const newDate = new Date(value);
        newDate.setMilliseconds(0);
        newDate.setSeconds(0);
        if (prevDate.getTime() !== newDate.getTime())
          changedValues[key] = value;
      } else if (prevValue instanceof File || value instanceof File) {
        if (!prevValue || !value) changedValues[key] = value;
        else if (prevValue.name !== value.name) changedValues[key] = value;
        else if (prevValue.lastModified !== value.lastModified)
          changedValues[key] = value;
      } else if (
        prevValue &&
        prevValue.value &&
        prevValue.label &&
        value &&
        value.value &&
        value.label
      ) {
        if (prevValue.value !== value.value) changedValues[key] = value;
      } else changedValues[key] = value;
    }
  });
  if (Object.keys(changedValues).length === 0) return null;
  return changedValues;
}

const getPageName = () => {
  const path = window.location.hash;
  return path
}

const isContestNeutral = (type: string | undefined | null) => {
  if (type)
    return ['NEUTRAL', 'CHALLENGE_CREATED', 'CHALLENGE_CONTINUED'].includes(type)
  return false
}

const getAgeOfCreator = (date: string) => {
  const timelines = getTimeSince(date, true) as TimelinesDataTypes
  if (timelines.years === 0 && timelines.months === 0 && timelines.days === 0) return "Less than a day"
  return buildStringFromValues(timelines.years, timelines.months, timelines.days)
}


const utils = {
  isDev,
  getTime,
  dateFormatter,
  getAgeOfCreator,
  getURLParameter,
  getChangedValues,
  setDataToLocalStorage,
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  getPageName,
  isContestNeutral
};

export default utils;

const buildValueObject = (yDiff: number, mDiff: number, dDiff: number, hourDiff: number, minDiff: number, secDiff: number, firstDateWasLater: boolean) => {
  return {
    "years": yDiff,
    "months": mDiff,
    "days": dDiff,
    "hours": hourDiff,
    "minutes": minDiff,
    "seconds": secDiff,
    "firstDateWasLater": firstDateWasLater
  }
}

const pluralize = (num: number, word: string) => {
  return `${num} ${word}${num === 1 ? '' : 's'}`;
}

function buildStringFromValues(yDiff?: number, mDiff?: number, dDiff?: number, hourDiff?: number, minDiff?: number, secDiff?: number) {
  const result = [];

  if (yDiff) {
    result.push(pluralize(yDiff, 'yr'));
  }
  if (mDiff) {
    result.push(pluralize(mDiff, 'month'));
  }
  if (dDiff) {
    result.push(pluralize(dDiff, 'day'));
  }
  if (hourDiff) {
    result.push(pluralize(hourDiff, 'hr'));
  }
  if (minDiff) {
    result.push(pluralize(minDiff, 'min'));
  }
  if (secDiff) {
    result.push(pluralize(secDiff, 'sec'));
  }

  return result.join(' ');
}

const getTimeSince = (date: string, returnValueObject: boolean = false) => {
  let m1 = dayjs(date)
  let m2 = dayjs(new Date())
  let firstDateWasLater: boolean;

  m1.add(m2.utcOffset() - m1.utcOffset(), 'minutes'); // shift timezone of m1 to m2

  if (m1.isSame(m2)) {
    if (returnValueObject) {
      return buildValueObject(0, 0, 0, 0, 0, 0, false);
    }
    return "no diff";
  }
  if (m1.isAfter(m2)) {
    const tmp = m1;
    m1 = m2;
    m2 = tmp;
    firstDateWasLater = true;
  } else {
    firstDateWasLater = false;
  }

  let yDiff = m2.year() - m1.year();
  let mDiff = m2.month() - m1.month();
  let dDiff = m2.date() - m1.date();
  let hourDiff = m2.hour() - m1.hour();
  let minDiff = m2.minute() - m1.minute();
  let secDiff = m2.second() - m1.second();

  if (secDiff < 0) {
    secDiff = 60 + secDiff;
    minDiff -= 1;
  }
  if (minDiff < 0) {
    minDiff = 60 + minDiff;
    hourDiff -= 1;
  }
  if (hourDiff < 0) {
    hourDiff = 24 + hourDiff;
    dDiff -= 1;
  }
  if (dDiff < 0) {
    const daysInLastFullMonth = dayjs(`${m2.year()}-${m2.month() + 1}`, "YYYY-MM").subtract(1, 'M').daysInMonth();
    if (daysInLastFullMonth < m1.date()) { // 31/01 -> 2/03
      dDiff = daysInLastFullMonth + dDiff + (m1.date() - daysInLastFullMonth);
    } else {
      dDiff = daysInLastFullMonth + dDiff;
    }
    mDiff -= 1;
  }
  if (mDiff < 0) {
    mDiff = 12 + mDiff;
    yDiff -= 1;
  }

  if (returnValueObject) {
    return buildValueObject(yDiff, mDiff, dDiff, hourDiff, minDiff, secDiff, firstDateWasLater);
  }
  return buildStringFromValues(yDiff, mDiff, dDiff, hourDiff, minDiff, secDiff);


};



