import {
  FC,
  lazy,
  Suspense,
  useEffect,
  useCallback,
  LazyExoticComponent,
} from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { initializeApp } from 'firebase/app';

import RoutesUrls from './RouteUrls';
import firebaseKeys from '../../config/firebase';
import GlobalLoader from '../components/Atom/Loader';
import { userAuthDetails } from '../recoilState/authState';
import { apiAuth } from '../services';
import UseApiCall from '../Hooks/useApiCall';
import { utils } from '../Utils';

const DashboardRoute = lazy(() => import('./DashboardRoute'));
const LoginPage = lazy(() => import('../pages/LoginPage'));

initializeApp(firebaseKeys);

const MainRouter = () => {
  const [userData, setUserData] = useRecoilState(userAuthDetails);
  const [getUserData, userDataLoading, , useDataError] = UseApiCall(
    apiAuth,
    (data: any) => {
      const userDataObj = data.results[0];
      const deviceId = utils.getDataFromLocalStorage('DEVICE_ID');
      setUserData({
        ...userDataObj,
        userId: userDataObj.id,
        device_id: deviceId,
      });
    }
  );

  useEffect(() => {
    getUserDetails();
  }, []);

  const getFallBackLoader = useCallback(() => {
    return <GlobalLoader />;
  }, []);

  const getUserDetails = () => {
    if (!userDataLoading && !useDataError) {
      const userId = utils.getDataFromLocalStorage('USER_ID');
      const deviceId = utils.getDataFromLocalStorage('DEVICE_ID');
      if (deviceId) getUserData({ id: userId, device_id: deviceId });
    }
  };

  const routeGuard = useCallback(
    (Component: LazyExoticComponent<FC<{}>>) => {
      if (!userData) {
        const savedToken = localStorage.getItem('TOKEN');
        if (!savedToken) return <Redirect to={RoutesUrls.Login} />;
      }
      return <Component />;
    },
    [userData, getUserDetails]
  );

  const loginRoute = useCallback(() => {
    if (userData || localStorage.getItem('TOKEN'))
      return <Redirect to={RoutesUrls.Dashboard} />;
    return <LoginPage />;
  }, [userData, RoutesUrls.Dashboard]);

  const routing = useCallback(() => {
    return (
      <Box>
        <Suspense fallback={getFallBackLoader()}>
          <BrowserRouter basename="#">
            <Switch>
              <Route exact path={RoutesUrls.Login} render={loginRoute} />

              {/* all the pages with nav bar comes under this */}
              <Route
                path={RoutesUrls.Dashboard}
                render={() => routeGuard(DashboardRoute)}
              />

              {/* all the page that won't need a nav bar come here
            <Route path={RoutesUrls.Contest} component={LoginPage} /> */}

              <Redirect to={RoutesUrls.DefaultUrl} />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </Box>
    );
  }, [getFallBackLoader, loginRoute, DashboardRoute, RoutesUrls]);

  return routing();
};

export default MainRouter;
