import { Suspense, lazy, FC, createRef } from 'react';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import branch from 'branch-sdk';

import theme from '../MuiWrapper/theme';
import ErrorBoundary from './components/Atom/ErrorBoundary/ErrorBoundary';
import Router from './Router';
import BackgroundMediaUploader from './components/Atom/BackgroundMediaUploader';
import CloseIcon from '../asset/closeIcon.svg';
import keys from '../config/keys';

const GlobalLoader = lazy(() => import('./components/Atom/Loader'));

const App: FC = () => {
  const getFallBackLoader = () => <GlobalLoader />;
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: any) => {
    notistackRef?.current?.closeSnackbar(key);
  };
  const options = { no_journeys: true };
  branch.init(keys.branch, options, (err, data) => {});

  return (
    <>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <IconButton size="medium" onClick={() => onClickDismiss(key)}>
                <img src={CloseIcon} alt="X" width="15px" height="15px" />
              </IconButton>
            )}
            maxSnack={4}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <RecoilRoot>
              <Suspense fallback={getFallBackLoader()}>
                <BackgroundMediaUploader />
                <Router />
              </Suspense>
            </RecoilRoot>
          </SnackbarProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
