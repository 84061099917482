const keys =
    process.env.REACT_APP_NODE_ENV === 'production' ? 
    {
        branch: 'key_live_je6CTS2n1BvNj2pXFc1JZjiirEetcjk2'
    }: {
        branch: 'key_test_gl9rGN6e3CzOk7h3wFvLIhjeFvcDkgwm'
    }



export default keys